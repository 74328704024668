<script>
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A module that displays promotional content. Can be arranged into a grid using the `BasePromoModuleGrid` component.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** The module's background color. */
      color: {
        type: String,
        default: 'green-dark',
        validator: (value) => ['green-dark', 'primary'].includes(value),
      },
      /** The tag's background color. */
      tagColor: {
        type: String,
        default: 'gray',
      },
      /** The tag's text color. */
      tagTextColor: {
        type: String,
        default: 'white',
      },
      /** The module's layout shape. */
      shape: {
        type: String,
        default: 'square',
        validator: (value) => ['square', 'landscape', 'portrait'].includes(value),
      },
    },
  }
</script>

<template>
  <!-- for purgecss: shape--square shape--landscape shape--portrait color--green-dark color--primary -->
  <component
    :is="hasLink ? 'BaseLink' : 'div'"
    :class="`shape--${shape} color--${color}`"
    v-bind="linkProps"
    v-on="$listeners"
  >
    <div class="absolute z-10 mt-2">
      <BaseTag v-if="$scopedSlots.tag" :color="tagColor" :text-color="tagTextColor">
        <!-- @slot Optional. Tag placed in the top left corner. Should contain text. -->
        <slot name="tag" />
      </BaseTag>
    </div>

    <div v-if="$scopedSlots.image">
      <!-- @slot Optional. If module content is an image. -->
      <slot name="image" />
    </div>

    <div v-else class="h-full text-left flex flex-wrap content-center">
      <div v-if="$scopedSlots.default">
        <!-- @slot Optional. Module content consisting of text. -->
        <slot />
      </div>

      <div v-if="$scopedSlots.cta">
        <!-- @slot Optional. CTA text. Should contain text and/or icon. -->
        <slot name="cta" />
      </div>
    </div>
  </component>
</template>

<style lang="postcss" scoped>
  .shape {
    &--square {
      @apply col-span-1;
    }

    &--landscape {
      @apply col-span-2;
    }

    &--portrait {
      @apply row-span-2;
    }
  }

  .color {
    &--green-dark {
      @apply bg-green-dark text-white;
    }

    &--primary {
      @apply bg-primary text-white;
    }
  }
</style>
